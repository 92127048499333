import "./App.css";
import { useState, Fragment, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import brochure from "./assets/brochuremock.png";
import fiftyOff from "./assets/50.png";
import sixtyOff from "./assets/60.png";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import axios from "axios";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import qs from "qs";

function App() {
  //const [couponData, setCouponData] = useState([{code: 'XXXYYY', discount: 50}, {code: 'AAABBB', discount: 60}]);
  const [couponInput, setCouponInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [finalResult, setFinalResult] = useState(false);
  const [result, setResult] = useState({});
  const [couponError, setCouponError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    mobile: "",
    location: "Omaha West"
  });
  const [formErrors, setFormErrors] = useState({});
  const [showClaim, setShowClaim] = useState(false);
  const [showConsult, setShowConsult] = useState(false);
  const [claimLoading, setClaimLoading] = useState(false);
  const [consultLoading, setConsultLoading] = useState(false);
  const [claimSuccess, setClaimSuccess] = useState(false);
  const [consultSuccess, setConsultSuccess] = useState(false);
  const [timer, setTimer] = useState({
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });
  const myRef = useRef(null);
  var regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const handleClick = () => {
    if (couponInput.length < 5) {
      setCouponError(true);
      setErrorText("Coupon code must be at least 5 digits");
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setLoading(true);
      axios
        .post(
          "https://cors-milanlaser.herokuapp.com/https://us-central1-milanlaser-fcb24.cloudfunctions.net/api/getCouponData",
          { couponCode: couponInput.toUpperCase() }
        )
        .then(res => {
          console.log(res.data);
          setResult(res.data);
          showTimer(res.data.expDate);
          setFormData({
            ...formData,
            location:
              res.data.storeName === "NORMAN - OK"
                ? "Oklahoma City (Norman)"
                : res.data.storeName === "ODESSA - TX"
                ? "Odessa"
                : res.data.storeName === "PEABODY - MA"
                ? "Boston (Peabody)"
                : res.data.storeName
          });
          setLoading(false);
          res.data.discount !== "0"
            ? setFinalResult(true)
            : (() => {
                setCouponError(true);
                setErrorText("Please check the Coupon code entered");
              })();
        });
    }
  };

  const showTimer = date => {
    var notNow = moment(date, "MM-DD-YYYY")
      .toDate()
      .getTime();
    var now = new Date().getTime();
    var diffTime = notNow - now;
    var duration = moment.duration(diffTime, "milliseconds");
    var interval = 1000;
    setInterval(() => {
      duration = moment.duration(duration - interval, "milliseconds");
      setTimer({
        months: duration.months(),
        days: duration.days(),
        hours: duration.hours(),
        minutes: duration.minutes(),
        seconds: duration.seconds()
      });
    }, interval);
  };

  const handleBookClaim = () => {
    if (formData.email === "") {
      setFormErrors({ email: "Email Cannot be empty." });
    } else if (!regex.test(formData.email)) {
      setFormErrors({ email: "Please enter a valid Email" });
    } else {
      myRef.current.scrollIntoView();
      setClaimLoading(true);
      axios
        .post(
          "https://milanlaser.com/code/email_sending.php",
          {
            email: formData.email,
            code: couponInput,
            discount: result.discount,
            date: moment(result.expDate, "MM-DD-YYYY").format("MMM Do YYYY")
          },
          {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              "Cache-Control": "no-cache"
            }
          }
        )
        .then(() => {
          axios.post(
            "https://cors-milanlaser.herokuapp.com/https://us-central1-milanlaser-fcb24.cloudfunctions.net/api/claimCoupon",
            {
              couponCode: couponInput.toUpperCase(),
              email: formData.email,
              discount: result.discount
            }
          );
        })
        .then(() => {
          setClaimLoading(false);
          setClaimSuccess(true);
        });
    }
  };

  const handleBookConsult = () => {
    var tempErrors = {};
    if (formData.email === "") {
      tempErrors.email = "Email cannot be empty";
    } else if (!regex.test(formData.email)) {
      tempErrors.email = "Please enter a valid Email";
    } else {
    }
    if (formData.name === "") {
      tempErrors.name = "Name cannot be empty";
    } else {
    }
    //if (formData.last_name === '') { tempErrors.last_name = 'Last Name cannot be empty' } else { }
    if (formData.mobile === "") {
      tempErrors.mobile = "Mobile number cannot be empty";
    } else if (formData.mobile.length < 10) {
      tempErrors.mobile = "Please enter a valid phone number";
    } else {
    }
    if (Object.keys(tempErrors).length === 0) {
      myRef.current.scrollIntoView();
      setConsultLoading(true);
      axios({
        method: "POST",
        url:
          "https://cors-milanlaser.herokuapp.com/https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8",
        data: qs.stringify({
          first_name: formData.name,
          last_name: "",
          email: formData.email,
          mobile: formData.mobile.replace(/[-()\s]/g, ""),
          "00N1L00000F9eBV": formData.location,
          oid: "00D410000014bPe",
          lead_source: "website",
          retUrl: "https://milanlaser.com",
          Campaign_ID: "7015d000001wLKJ",
          description: `Direct Mail Consult - CouponCode - ${couponInput.toUpperCase()} - Discount - ${
            result.discount
          }`
        }),
        config: {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
      })
        .then(res => {
          axios.post(
            "https://cors-milanlaser.herokuapp.com/https://us-central1-milanlaser-fcb24.cloudfunctions.net/api/updateCouponData",
            {
              couponCode: couponInput.toUpperCase(),
              name: formData.name,
              email: formData.email,
              mobile: formData.mobile
            }
          );
        })
        .then(res => {
          setConsultLoading(false);
          setConsultSuccess(true);
        });
    } else {
      setFormErrors(tempErrors);
    }
  };

  const handleMobileChange = e => {
    console.log(e.target.value);

    if (e.target.value.length < 10) {
      const onlyNums = e.target.value.replace(/[^0-9]/g, "");
      setFormData({ ...formData, mobile: onlyNums });
    } else if (e.target.value.length === 10) {
      const onlyNums = e.target.value.replace(/[^0-9]/g, "");
      const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, "+1 ($1) $2-$3");
      setFormData({ ...formData, mobile: number });
    } else {
      setFormData({ ...formData, mobile: e.target.value });
    }
  };
  const handleFormChange = event => {
    const name = event.target.name;
    setFormData({
      ...formData,
      [name]: event.target.value
    });
  };
  return (
    <Fragment>
      <Grid ref={myRef} container style={{ height: 65 }}>
        &nbsp;
      </Grid>
      {!finalResult && (
        <Grid
          container
          style={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 15
          }}
        >
          <Typography variant="h5">Enter Your Code to</Typography>
          <Typography variant="h4">See Your Savings</Typography>
        </Grid>
      )}
      {loading ? (
        <Grid
          container
          style={{ textAlign: "center", marginTop: 25, marginBottom: 25 }}
        >
          <Grid item sm={12} md={12} style={{ margin: "auto" }}>
            {/*<img alt="loading gif" width="300" src={loadingImg} />*/}
            <CircularProgress
              style={{ marginTop: 25, marginBottom: 25 }}
              size={100}
            />
          </Grid>
        </Grid>
      ) : finalResult ? (
        <Grid container style={{ marginTop: 25, marginBottom: 25 }}>
          {!(
            claimSuccess ||
            consultSuccess ||
            consultLoading ||
            claimLoading
          ) && (
            <Grid
              container
              style={{
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 5
              }}
            >
              <Typography variant="h5">Congrats!</Typography>
              <Typography variant="h4">Your Discount!</Typography>
            </Grid>
          )}
          <Grid
            item
            sm={12}
            md={12}
            style={{ textAlign: "center", margin: "auto" }}
          >
            {!(
              claimSuccess ||
              consultSuccess ||
              consultLoading ||
              claimLoading
            ) && (
              <>
                <img
                  src={result.discount === 50 ? fiftyOff : sixtyOff}
                  alt="discount"
                  width="300"
                ></img>
                {result.discount === 60 && (
                  <Typography variant="caption" style={{ display: "block" }}>
                    60% Off cannot be used with Full Body.
                  </Typography>
                )}
              </>
            )}
            {!(
              claimSuccess ||
              consultSuccess ||
              consultLoading ||
              claimLoading
            ) && (
              <Fragment>
                <Typography variant="h6" style={{ marginTop: 15 }}>
                  Time Left
                </Typography>
                <Grid
                  container
                  style={{
                    textAlign: "center",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <Grid item sm={3} md={1}>
                    <div style={{ padding: 15 }}>
                      <Typography variant="h4">
                        {timer.months * 31 + timer.days}
                      </Typography>
                      days
                    </div>
                  </Grid>
                  <Grid item sm={3} md={1}>
                    <div style={{ padding: 15 }}>
                      <Typography variant="h4">{timer.hours}</Typography>Hours
                    </div>
                  </Grid>
                  <Grid item sm={3} md={1}>
                    <div style={{ padding: 15 }}>
                      <Typography variant="h4">{timer.minutes}</Typography>
                      Minutes
                    </div>
                  </Grid>
                  <Grid item sm={3} md={1}>
                    <div style={{ padding: 15 }}>
                      <Typography variant="h4">{timer.seconds}</Typography>
                      Seconds
                    </div>
                  </Grid>
                </Grid>
              </Fragment>
            )}

            {!(showClaim || showConsult) && (
              <Button
                onClick={() => {
                  setShowClaim(true);
                  setShowConsult(false);
                }}
                style={{
                  display: "block",
                  margin: "auto",
                  marginTop: 35,
                  backgroundColor: "#f93500",
                  color: "white",
                  borderRadius: 0
                }}
              >
                Claim your {result.discount}% Discount
              </Button>
            )}
            {showClaim && (
              <Fragment>
                {claimLoading ? (
                  <Grid item sm={12} md={12}>
                    <CircularProgress
                      style={{ marginTop: 100, marginBottom: 100 }}
                    />
                  </Grid>
                ) : claimSuccess ? (
                  <Grid
                    item
                    sm={12}
                    md={12}
                    style={{
                      textAlign: "center",
                      marginTop: 5,
                      marginLeft: "10%",
                      marginRight: "10%"
                    }}
                  >
                    <Typography variant="h4">Congrats!</Typography>
                    <Typography variant="h5">
                      You’ve successfully claimed your {result.discount}%
                      discount.
                    </Typography>
                    <br />
                    <Typography>
                      You should receive an email from us shortly confirming
                      your discount through{" "}
                      {moment(result.expDate, "MM-DD-YYYY").format(
                        "MMM Do YYYY"
                      )}
                    </Typography>
                    <br />
                    <Typography>
                      You can redeem your {result.discount}% off unlimited laser
                      hair removal offer anytime before the end of February by
                      scheduling a free consult. It’s the perfect opportunity to
                      get all of your laser hair removal questions answered by
                      an expert.
                    </Typography>
                    <br />
                    <Typography>
                      Plus it’s free, so you have nothing to lose… except
                      unwanted hair and of course the hassles of shaving and
                      waxing!
                    </Typography>
                  </Grid>
                ) : (
                  <Fragment>
                    <Typography variant="h6" style={{ marginBottom: 15 }}>
                      Claim your Discount
                    </Typography>
                    <TextField
                      name="email"
                      value={formData.email}
                      onChange={handleFormChange}
                      error={formErrors.email !== undefined}
                      helperText={
                        formErrors.email !== undefined ? formErrors.email : null
                      }
                      style={{ marginTop: 5, marginBottom: 5, width: 300 }}
                      variant="outlined"
                      label="Email"
                    />
                    <Button
                      onClick={handleBookClaim}
                      style={{
                        display: "block",
                        margin: "auto",
                        marginTop: 25,
                        backgroundColor: "#f93500",
                        color: "white",
                        borderRadius: 0
                      }}
                    >
                      Submit
                    </Button>
                  </Fragment>
                )}
              </Fragment>
            )}
            {!showConsult && (
              <Button
                onClick={() => {
                  setShowClaim(false);
                  setShowConsult(true);
                }}
                style={{
                  display: "block",
                  margin: "auto",
                  marginTop: 25,
                  backgroundColor: "#01718f",
                  color: "white",
                  borderRadius: 0
                }}
              >
                Book your Consult with {result.discount}% Off
              </Button>
            )}

            {showConsult && (
              <Grid container style={{ marginTop: 25 }}>
                {consultLoading ? (
                  <Grid item sm={12} md={12}>
                    <CircularProgress
                      style={{ marginTop: 100, marginBottom: 100 }}
                    />
                  </Grid>
                ) : consultSuccess ? (
                  <Grid
                    item
                    sm={12}
                    md={12}
                    style={{
                      textAlign: "center",
                      marginLeft: "10%",
                      marginRight: "10%",
                      marginBottom: 35
                    }}
                  >
                    <Typography variant="h4">Thank You!</Typography>
                    <Typography variant="h4">We'll be in touch.</Typography>
                    <br />
                    <Typography variant="h5">
                      You’ve successfully requested a consult with{" "}
                      {result.discount}% discount.
                    </Typography>
                    <br />
                    <Typography>
                      Our team is excited to help you get the smooth, hair-free
                      skin that you deserve! We will be reaching out to you
                      shortly to schedule a convenient time for you to come in
                      for your free consultation. In the meantime, if you have
                      any questions please contact us at{" "}
                      <a href="tel:833-667-2967">1-833-NO-RAZOR</a>
                    </Typography>
                  </Grid>
                ) : (
                  <Grid
                    item
                    sm={12}
                    md={12}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center"
                    }}
                  >
                    <Typography variant="h6">Book Your Consult</Typography>
                    <TextField
                      variant="outlined"
                      name="name"
                      value={formData.name}
                      onChange={handleFormChange}
                      error={formErrors.name !== undefined}
                      helperText={
                        formErrors.name !== undefined ? formErrors.name : null
                      }
                      style={{ marginTop: 5, marginBottom: 5, width: 300 }}
                      label="Name"
                    />
                    <TextField
                      variant="outlined"
                      name="email"
                      value={formData.email}
                      error={formErrors.email !== undefined}
                      helperText={
                        formErrors.email !== undefined ? formErrors.email : null
                      }
                      onChange={handleFormChange}
                      style={{ marginTop: 5, marginBottom: 5, width: 300 }}
                      label="Email"
                    />
                    <TextField
                      variant="outlined"
                      inputProps={{ maxLength: 17 }}
                      name="mobile"
                      error={formErrors.mobile !== undefined}
                      helperText={
                        formErrors.mobile !== undefined
                          ? formErrors.mobile
                          : null
                      }
                      value={formData.mobile}
                      onChange={handleMobileChange}
                      style={{ marginTop: 5, marginBottom: 5, width: 300 }}
                      label="Mobile"
                    />
                    <FormControl
                      variant="outlined"
                      style={{ width: 300, marginTop: 5 }}
                    >
                      <InputLabel htmlFor="locations-dropdown">
                        Location
                      </InputLabel>
                      <Select
                        native
                        value={formData.location}
                        onChange={handleFormChange}
                        label="Location"
                        inputProps={{
                          name: "location",
                          id: "locations-dropdown"
                        }}
                      >
                        <optgroup label="Arizona">
                          <option
                            value="Phoenix (Chandler)"
                            zip="85226"
                            className="mt"
                          >
                            Phoenix (Chandler)
                          </option>
                          <option
                            value="Phoenix (Peoria)"
                            zip="85382"
                            className="mt"
                          >
                            Phoenix (Peoria)
                          </option>
                          <option
                            value="Phoenix (Scottsdale North)"
                            zip="85254"
                            className="mt"
                          >
                            Phoenix (Scottsdale North)
                          </option>
                          <option
                            value="Phoenix (Scottsdale South)"
                            zip="85251"
                            className="mt"
                          >
                            Phoenix (Scottsdale South)
                          </option>
                          <option value="Tucson" zip="85705" className="mt">
                            Tucson
                          </option>
                        </optgroup>
                        <optgroup label="Colorado">
                          <option
                            value="Boulder"
                            zip="80302"
                            className="mt loc_cs"
                          >
                            Boulder (Coming Soon)
                          </option>
                          <option
                            value="Colorado Springs"
                            zip="80922"
                            className="mt"
                          >
                            {" "}
                            Colorado Springs
                          </option>
                          <option
                            value="Colorado Springs (South)"
                            zip="80906"
                            className="mt loc_cs"
                          >
                            Colorado Springs (South) / Coming Soon
                          </option>
                          <option
                            value="Denver East"
                            zip="80012"
                            className="mt"
                          >
                            Denver (Aurora)
                          </option>
                          <option
                            value="Denver North"
                            zip="80233"
                            className="mt"
                          >
                            Denver (Northglenn)
                          </option>
                          <option
                            value="Denver South"
                            zip="80110"
                            className="mt"
                          >
                            Denver (Englewood)
                          </option>
                          <option
                            value="Denver West (Wheat Ridge)"
                            zip="80033"
                            className="mt"
                          >
                            Denver (Wheat Ridge)
                          </option>
                          <option
                            value="Denver (Northfield)"
                            zip="80238"
                            className="mt"
                          >
                            Denver (Northfield)
                          </option>
                          <option
                            value="Denver (Parker)"
                            zip="80134"
                            className="mt"
                          >
                            Denver (Parker)
                          </option>
                          <option
                            value="Denver (Centennial)"
                            zip="80112"
                            className="mt"
                          >
                            Denver (Centennial)
                          </option>
                          <option
                            value="Ft. Collins"
                            zip="80528"
                            className="mt"
                          >
                            Ft. Collins
                          </option>
                          <option value="Greeley" zip="80634" className="mt">
                            Greeley
                          </option>
                        </optgroup>
                        <optgroup label="Connecticut">
                          <option
                            value="Danbury"
                            zip="06810"
                            className="est loc_cs"
                          >
                            Danbury / Coming Soon
                          </option>
                          <option
                            value="Hartford (Avon)"
                            zip="06001"
                            className="est loc_cs"
                          >
                            Hartford (Avon) / Coming Soon
                          </option>
                          <option
                            value="Hartford (Newington)"
                            zip="06111"
                            className="est loc_cs"
                          >
                            Hartford (Newington) / Coming Soon
                          </option>
                          <option
                            value="Hartford (South Windsor)"
                            zip="06074"
                            className="est"
                          >
                            Hartford (South Windsor)
                          </option>
                          <option
                            value="New Haven"
                            zip="06510"
                            className="est loc_cs"
                          >
                            New Haven / Coming Soon
                          </option>
                          <option
                            value="Southington"
                            zip="06489"
                            className="est loc_cs"
                          >
                            Southington / Coming Soon
                          </option>
                        </optgroup>
                        <optgroup label="Georgia">
                          <option value="Athens" zip="30622" className="est">
                            Athens
                          </option>
                          <option
                            value="Atlanta (Alpharetta)"
                            zip="30022"
                            className="est"
                          >
                            Atlanta (Alpharetta)
                          </option>
                          <option
                            value="Atlanta (Buckhead)"
                            zip="30324"
                            className="est"
                          >
                            Atlanta (Buckhead)
                          </option>
                          <option
                            value="Atlanta (Decatur)"
                            zip="30033"
                            className="est"
                          >
                            Atlanta (Decatur)
                          </option>
                          <option
                            value="Atlanta (Gwinnett)"
                            zip="30096"
                            className="est"
                          >
                            Atlanta (Gwinnett)
                          </option>
                          <option
                            value="Atlanta (Woodstock)"
                            zip="30189"
                            className="est"
                          >
                            Atlanta (Woodstock)
                          </option>
                          <option
                            value="Atlanta (Newnan)"
                            zip="30265"
                            className="est"
                          >
                            Atlanta (Newnan)
                          </option>
                          <option
                            value="Atlanta (McDonough)"
                            zip="30253"
                            className="est"
                          >
                            Atlanta (McDonough)
                          </option>
                          <option
                            value="Atlanta (Smyrna)"
                            zip="30080"
                            className="est"
                          >
                            Atlanta (Smyrna)
                          </option>
                          <option
                            value="Atlanta (Sandy Springs)"
                            zip="30328"
                            className="est"
                          >
                            Atlanta (Sandy Springs)
                          </option>
                          <option value="Augusta" zip="30909" className="est">
                            Augusta
                          </option>
                          <option value="Columbus" zip="31904" className="est">
                            {" "}
                            Columbus
                          </option>
                          <option value="Savannah" zip="31406" className="est">
                            Savannah
                          </option>
                        </optgroup>
                        <optgroup label="Idaho">
                          <option
                            value="Boise"
                            zip="83704"
                            className="mt loc_cs"
                          >
                            {" "}
                            Boise / Coming Soon
                          </option>
                          <option
                            value="Boise (Eagle)"
                            zip="83616"
                            className="mt loc_cs"
                          >
                            Boise (Eagle) / Coming Soon
                          </option>
                          <option
                            value="Idaho Falls"
                            zip="83406"
                            className="mt loc_cs"
                          >
                            Idaho Falls / Coming Soon
                          </option>
                          <option
                            value="Twin Falls"
                            zip="83301"
                            className="mt loc_cs"
                          >
                            Twin Falls / Coming Soon
                          </option>
                        </optgroup>
                        <optgroup label="Illinois">
                          <option value="Champaign" zip="61822" className="cst">
                            Champaign
                          </option>
                          <option
                            value="Chicago (Aurora)"
                            zip="60504"
                            className="cst"
                          >
                            Chicago (Aurora, IL)
                          </option>
                          <option
                            value="Chicago (Orland Park)"
                            zip="60462"
                            className="cst"
                          >
                            Chicago (Orland Park)
                          </option>
                          <option
                            value="Chicago (Oakbrook Terrace)"
                            zip="60181"
                            className="cst"
                          >
                            Chicago (Oakbrook Terrace)
                          </option>
                          <option
                            value="Chicago (Schaumburg)"
                            zip="60173"
                            className="cst"
                          >
                            Chicago (Schaumburg)
                          </option>
                          <option
                            value="Chicago (Schererville)"
                            zip="46375"
                            className="cst"
                          >
                            Chicago (Schererville)
                          </option>
                          <option
                            value="Chicago (Skokie)"
                            zip="60077"
                            className="cst"
                          >
                            Chicago (Skokie)
                          </option>
                          <option
                            value="Chicago (Vernon Hills)"
                            zip="60061"
                            className="cst"
                          >
                            Chicago (Vernon Hills)
                          </option>
                          <option
                            value="Chicago (Bucktown)"
                            zip="60647"
                            className="cst loc_cs"
                          >
                            Chicago (Bucktown) / Coming Soon
                          </option>
                          <option
                            value="Chicago (Bolingbrook)"
                            zip="60440"
                            className="cst"
                          >
                            Chicago (Bolingbrook)
                          </option>
                          <option
                            value="Chicago (Bloomingdale)"
                            zip="60108"
                            className="cst"
                          >
                            Chicago (Bloomingdale)
                          </option>
                          <option
                            value="Chicago (Hyde Park)"
                            zip="60615"
                            className="cst"
                          >
                            Chicago (Hyde Park)
                          </option>
                          <option
                            value="Chicago (Halsted)"
                            zip="60661"
                            className="cst loc_cs"
                          >
                            Chicago (Halsted) / Coming Soon
                          </option>
                          <option
                            value="Chicago (Berwyn)"
                            zip="60402"
                            className="cst loc_cs"
                          >
                            Chicago (Berwyn) / Coming Soon
                          </option>
                          <option
                            value="Chicago (Uptown)"
                            zip="60640"
                            className="cst loc_cs"
                          >
                            Chicago (Uptown) / Coming Soon
                          </option>
                          <option value="DeKalb" zip="60115" className="cst">
                            DeKalb
                          </option>
                          <option
                            value="St. Louis (Edwardsville)"
                            zip="62025"
                            className="cst"
                          >
                            Edwardsville
                          </option>
                          <option value="Peoria" zip="61615" className="cst">
                            Peoria
                          </option>
                          <option value="Rockford" zip="61108" className="cst">
                            Rockford
                          </option>
                          <option
                            value="Springfield IL"
                            zip="62704"
                            className="cst"
                          >
                            Springfield, IL
                          </option>
                        </optgroup>
                        <optgroup label="Indiana">
                          <option
                            value="Chicago (Schererville)"
                            zip="46375"
                            className="cst"
                          >
                            Chicago (Schererville)
                          </option>
                          <option
                            value="Elkhart"
                            zip="46517"
                            className="est loc_cs"
                          >
                            Elkhart / Coming Soon
                          </option>
                          <option
                            value="Evansville"
                            zip="47715"
                            className="cst"
                          >
                            Evansville
                          </option>
                          <option
                            value="Fort Wayne"
                            zip="46804"
                            className="est"
                          >
                            Fort Wayne (West)
                          </option>
                          <option
                            value="Fort Wayne (North)"
                            zip="46825"
                            className="est"
                          >
                            Fort Wayne (North)
                          </option>
                          <option
                            value="Indianapolis North"
                            zip="46240"
                            className="est"
                          >
                            Indianapolis North
                          </option>
                          <option
                            value="Indianapolis South (Greenwood)"
                            zip="46143"
                            className="est"
                          >
                            Indianapolis S. (Greenwood)
                          </option>
                          <option
                            value="Indianapolis West (Plainfield)"
                            zip="46168"
                            className="est"
                          >
                            Indianapolis W. (Plainfield)
                          </option>
                          <option
                            value="South Bend"
                            zip="46530"
                            className="est"
                          >
                            South Bend
                          </option>
                          <option
                            value="Valparaiso"
                            zip="46383"
                            className="cst"
                          >
                            Valparaiso
                          </option>
                        </optgroup>
                        <optgroup label="Iowa">
                          <option value="Ankeny" zip="50021" className="cst">
                            Ankeny
                          </option>
                          <option
                            value="Cedar Rapids"
                            zip="52302"
                            className="cst"
                          >
                            Cedar Rapids (North)
                          </option>
                          <option
                            value="Cedar Rapids (West)"
                            zip="52404"
                            className="cst"
                          >
                            Cedar Rapids (West)
                          </option>
                          <option
                            value="Coralville"
                            zip="52241"
                            className="cst"
                          >
                            Coralville
                          </option>
                          <option
                            value="Des Moines"
                            zip="50325"
                            className="cst"
                          >
                            West Des Moines
                          </option>
                          <option
                            value="Quad Cities"
                            zip="52807"
                            className="cst"
                          >
                            Quad Cities (Davenport)
                          </option>
                        </optgroup>
                        <optgroup label="Kansas">
                          <option
                            value="Overland Park"
                            zip="66215"
                            className="cst"
                          >
                            Kansas City (Overland Park)
                          </option>
                          <option value="Topeka" zip="66604" className="cst">
                            Topeka
                          </option>
                          <option value="Wichita" zip="67206" className="cst">
                            {" "}
                            Wichita
                          </option>
                          <option
                            value="Wichita (West)"
                            zip="67205"
                            className="cst"
                          >
                            Wichita (West)
                          </option>
                        </optgroup>
                        <optgroup label="Kentucky">
                          <option
                            value="Cincinnati (Florence)"
                            zip="41042"
                            className="est"
                          >
                            Cincinnati (Florence)
                          </option>
                          <option value="Lexington" zip="40503" className="est">
                            {" "}
                            Lexington
                          </option>
                          <option
                            value="Lexington (East)"
                            zip="40509"
                            className="est"
                          >
                            Lexington East
                          </option>
                          <option
                            value="Louisville"
                            zip="40243"
                            className="est"
                          >
                            Louisville East
                          </option>
                          <option
                            value="Louisville (West)"
                            zip="40218"
                            className="est"
                          >
                            Louisville West
                          </option>
                        </optgroup>
                        <optgroup label="Maine">
                          <option value="Portland" zip="04106" className="est">
                            Portland
                          </option>
                        </optgroup>
                        <optgroup label="Maryland">
                          <option
                            value="Baltimore (Abingdon)"
                            zip="21009"
                            className="est"
                          >
                            Baltimore (Abingdon)
                          </option>
                          <option
                            value="Baltimore (Owings Mills)"
                            zip="21117"
                            className="est"
                          >
                            Baltimore (Owings Mills)
                          </option>
                          <option
                            value="Frederick"
                            zip="21701"
                            className="est loc_cs"
                          >
                            Frederick / Coming Soon
                          </option>
                          <option
                            value="Washington (Gaithersburg)"
                            zip="20878"
                            className="est loc_cs"
                          >
                            Washington (Gaithersburg) / Coming Soon
                          </option>
                          <option
                            value="Washington (Laurel)"
                            zip="20707"
                            className="est loc_cs"
                          >
                            Washington (Laurel) / Coming Soon
                          </option>
                          <option
                            value="Washington (Odenton)"
                            zip="21054"
                            className="est"
                          >
                            Washington (Odenton)
                          </option>
                        </optgroup>
                        <optgroup label="Massachusetts">
                          <option
                            value="Boston (Brighton)"
                            zip="02135"
                            className="est loc_cs"
                          >
                            Boston (Brighton) / Coming Soon
                          </option>
                          <option
                            value="Boston (Burlington)"
                            zip="01803"
                            className="est loc_cs"
                          >
                            Boston (Burlington) / Coming Soon
                          </option>
                          <option
                            value="Boston (Dedham)"
                            zip="02026"
                            className="est loc_cs"
                          >
                            Boston (Dedham) / Coming Soon
                          </option>
                          <option
                            value="Boston (Peabody)"
                            zip="01960"
                            className="est"
                          >
                            Boston (Peabody)
                          </option>
                          <option
                            value="Boston (West End)"
                            zip="02114"
                            className="est loc_cs"
                          >
                            Boston (West End) / Coming Soon
                          </option>
                          <option
                            value="Boston (Revere)"
                            zip="02151"
                            className="est"
                          >
                            Boston (Revere)
                          </option>
                          <option value="Mansfield" zip="02048" className="est">
                            Mansfield
                          </option>
                          <option value="Worcester" zip="01607" className="est">
                            Worcester
                          </option>
                        </optgroup>
                        <optgroup label="Michigan">
                          <option value="Ann Arbor" zip="48104" className="est">
                            {" "}
                            Ann Arbor
                          </option>
                          <option
                            value="Ann Arbor (West)"
                            zip="48103"
                            className="est loc_cs"
                          >
                            Ann Arbor (West) / Coming Soon
                          </option>
                          <option
                            value="Detroit (Dearborn Heights)"
                            zip="48127"
                            className="est loc_cs"
                          >
                            Detroit (Dearborn Heights) / Coming Soon
                          </option>
                          <option
                            value="Detroit (Novi)"
                            zip="48377"
                            className="est"
                          >
                            Detroit (Novi)
                          </option>
                          <option
                            value="Detroit (Roseville)"
                            zip="48066"
                            className="est"
                          >
                            Detroit (Roseville)
                          </option>
                          <option
                            value="Detroit (Royal Oak)"
                            zip="48073"
                            className="est"
                          >
                            Detroit (Royal Oak)
                          </option>
                          <option
                            value="Detroit (Sterling Heights)"
                            zip="48313"
                            className="est"
                          >
                            Detroit (Sterling Heights)
                          </option>
                          <option
                            value="Detroit (Woodhaven)"
                            zip="48183"
                            className="est"
                          >
                            Detroit (Woodhaven)
                          </option>
                          <option
                            value="Grand Blanc"
                            zip="48439"
                            className="est"
                          >
                            Grand Blanc
                          </option>
                          <option
                            value="Grand Rapids"
                            zip="49512"
                            className="est"
                          >
                            Grand Rapids
                          </option>
                          <option
                            value="Grand Rapids (Grandville)"
                            zip="49418"
                            className="est"
                          >
                            Grand Rapids (Grandville)
                          </option>
                          <option value="Kalamazoo" zip="49002" className="est">
                            Kalamazoo (Portage)
                          </option>
                          <option
                            value="Kalamazoo (Comstock)"
                            zip="49048"
                            className="est"
                          >
                            Kalamazoo (Comstock)
                          </option>
                          <option value="Lansing" zip="48912" className="est">
                            {" "}
                            Lansing
                          </option>
                          <option
                            value="Lansing (Okemos)"
                            zip="48864"
                            className="est loc_cs"
                          >
                            Lansing (Okemos) / Coming Soon
                          </option>
                        </optgroup>
                        <optgroup label="Minnesota">
                          <option
                            value="Minneapolis (Apple Valley)"
                            zip="55124"
                            className="cst"
                          >
                            Minneapolis (Apple Valley)
                          </option>
                          <option value="Edina" zip="55435" className="cst">
                            Minneapolis (Edina)
                          </option>
                          <option
                            value="Maple Grove"
                            zip="55369"
                            className="cst"
                          >
                            Minneapolis (Maple Grove)
                          </option>
                          <option value="Woodbury" zip="55125" className="cst">
                            Minneapolis (Woodbury)
                          </option>
                          <option
                            value="Minneapolis (New Brighton)"
                            zip="55112"
                            className="cst"
                          >
                            Minneapolis (New Brighton)
                          </option>
                          <option
                            value="Minneapolis (Uptown)"
                            zip="55416"
                            className="cst"
                          >
                            Minneapolis (Uptown)
                          </option>
                          <option
                            value="Minneapolis (St. Paul)"
                            zip="55116"
                            className="cst"
                          >
                            Minneapolis (St. Paul)
                          </option>
                          <option
                            value="Minneapolis (Minnetonka)"
                            zip="55305"
                            className="cst"
                          >
                            Minneapolis (Minnetonka)
                          </option>
                          <option
                            value="Minneapolis (Vadnais Heights)"
                            zip="55127"
                            className="cst loc_cs"
                          >
                            Minneapolis (Vadnais Heights) / Coming Soon
                          </option>
                          <option
                            value="Rochester, MN"
                            zip="55904"
                            className="cst"
                          >
                            Rochester, MN
                          </option>
                          <option value="St. Cloud" zip="56301" className="cst">
                            St. Cloud
                          </option>
                        </optgroup>
                        <optgroup label="Missouri">
                          <option value="Brentwood" zip="63143" className="cst">
                            Brentwood
                          </option>
                          <option value="Columbia" zip="65203" className="cst">
                            Columbia
                          </option>
                          <option
                            value="Kansas City"
                            zip="64158"
                            className="cst"
                          >
                            Kansas City (Liberty)
                          </option>
                          <option
                            value="Kansas City (Lee&#39;s Summit)"
                            zip="64081"
                            className="cst"
                          >
                            Kansas City (Lee&#39;s Summit)
                          </option>
                          <option
                            value="Springfield MO"
                            zip="65804"
                            className="cst"
                          >
                            Springfield
                          </option>
                          <option value="OFallon" zip="63366" className="cst">
                            O'Fallon
                          </option>
                          <option
                            value="Overland Park"
                            zip="66215"
                            className="cst"
                          >
                            Kansas City (Overland Park)
                          </option>
                          <option
                            value="St. Louis (St. Charles)"
                            zip="63303"
                            className="cst"
                          >
                            St. Louis (St. Charles)
                          </option>
                          <option
                            value="Town &amp; Country"
                            zip="63017"
                            className="cst"
                          >
                            Town &amp; Country
                          </option>
                        </optgroup>
                        <optgroup label="Nebraska">
                          <option value="Lincoln" zip="68526" className="cst">
                            Lincoln South
                          </option>
                          <option
                            value="Lincoln (North)"
                            zip="68521"
                            className="cst"
                          >
                            Lincoln North
                          </option>
                          <option
                            value="Omaha West"
                            zip="68130"
                            className="cst"
                          >
                            Omaha West
                          </option>
                          <option
                            value="Omaha Central"
                            zip="68114"
                            className="cst"
                          >
                            Omaha Central
                          </option>
                        </optgroup>
                        <optgroup label="New York">
                          <option value="Albany" zip="12205" className="est">
                            Albany &nbsp;
                          </option>
                          <option
                            value="Binghamton"
                            zip="13850"
                            className="est"
                          >
                            Binghamton
                          </option>
                          <option
                            value="Buffalo North"
                            zip="14228"
                            className="est"
                          >
                            Buffalo North (Amherst)
                          </option>
                          <option
                            value="Buffalo South"
                            zip="14127"
                            className="est"
                          >
                            Buffalo South (Orchard Park)
                          </option>
                          <option
                            value="Long Island (Plainview)"
                            zip="11803"
                            className="et"
                          >
                            Long Island
                          </option>
                          <option value="Middletown" zip="10940" className="et">
                            Middletown
                          </option>
                          <option
                            value="Rochester, NY"
                            zip="14564"
                            className="est"
                          >
                            Rochester, NY
                          </option>
                          <option value="Syracuse" zip="13214" className="est">
                            Syracuse
                          </option>
                          <option value="Utica" zip="13413" className="est">
                            Utica
                          </option>
                        </optgroup>
                        <optgroup label="North Carolina">
                          <option value="Asheville" zip="28805" className="est">
                            Asheville
                          </option>
                          <option
                            value="Burlington"
                            zip="27215"
                            className="est"
                          >
                            Burlington
                          </option>
                          <option
                            value="Charlotte North"
                            zip="28262"
                            className="est"
                          >
                            Charlotte North
                          </option>
                          <option
                            value="Charlotte South"
                            zip="28226"
                            className="est"
                          >
                            Charlotte South
                          </option>
                          <option
                            value="Charlotte (Mooresville)"
                            zip="28117"
                            className="est"
                          >
                            Charlotte (Mooresville)
                          </option>
                          <option value="Durham" zip="27713" className="est">
                            Durham
                          </option>
                          <option
                            value="Durham (Chapel Hill)"
                            zip="27516"
                            className="est loc_cs"
                          >
                            Durham (Chapel Hill) / Coming Soon
                          </option>
                          <option
                            value="Fayetteville"
                            zip="28314"
                            className="est"
                          >
                            Fayetteville
                          </option>
                          <option
                            value="Greensboro"
                            zip="27407"
                            className="est"
                          >
                            Greensboro
                          </option>
                          <option
                            value="Greenville"
                            zip="27834"
                            className="est"
                          >
                            Greenville
                          </option>
                          <option value="Hickory" zip="28602" className="est">
                            Hickory
                          </option>
                          <option
                            value="Raleigh (Garner)"
                            zip="27529"
                            className="est"
                          >
                            Raleigh (Garner)
                          </option>
                          <option
                            value="Raleigh (Holly Springs)"
                            zip="27540"
                            className="est"
                          >
                            Raleigh (Holly Springs)
                          </option>
                          <option value="Raleigh" zip="27615" className="est">
                            {" "}
                            Raleigh
                          </option>
                          <option
                            value="Winston-Salem"
                            zip="27103"
                            className="est"
                          >
                            Winston-Salem
                          </option>
                        </optgroup>
                        <optgroup label="North Dakota">
                          <option value="Fargo" zip="58103" className="cst">
                            Fargo
                          </option>
                        </optgroup>
                        <optgroup label="Ohio">
                          <option value="Akron" zip="44313" className="est">
                            Akron
                          </option>
                          <option value="Canton" zip="44718" className="est">
                            Canton
                          </option>
                          <option
                            value="Cincinnati (Florence)"
                            zip="41042"
                            className="est"
                          >
                            Cincinnati (Florence)
                          </option>
                          <option
                            value="Cincinnati (Springdale)"
                            zip="45246"
                            className="est"
                          >
                            Cincinnati (Springdale)
                          </option>
                          <option
                            value="Cincinnati (Rookwood)"
                            zip="45209"
                            className="est"
                          >
                            Cincinnati (Rookwood)
                          </option>
                          <option
                            value="Cincinnati (Hamilton)"
                            zip="45011"
                            className="est loc_cs"
                          >
                            Cincinnati (Hamilton) / Coming Soon
                          </option>
                          <option
                            value="Cleveland (Lyndhurst)"
                            zip="44124"
                            className="est"
                          >
                            Cleveland (Lyndhurst)
                          </option>
                          <option
                            value="Cleveland (Rocky River)"
                            zip="44116"
                            className="est"
                          >
                            Cleveland (Rocky River)
                          </option>
                          <option
                            value="Columbus (Dublin)"
                            zip="43017"
                            className="est"
                          >
                            Columbus (Dublin)
                          </option>
                          <option
                            value="Columbus (Reynoldsburg)"
                            zip="43068"
                            className="est"
                          >
                            Columbus (Reynoldsburg)
                          </option>
                          <option
                            value="Columbus (New Albany)"
                            zip="43230"
                            className="est loc_cs"
                          >
                            Columbus (New Albany) / Coming Soon
                          </option>
                          <option value="Dayton" zip="45459" className="est">
                            Dayton
                          </option>
                          <option value="Toledo" zip="43623" className="est">
                            Toledo
                          </option>
                        </optgroup>
                        <optgroup label="Oklahoma">
                          <option
                            value="Oklahoma City (Norman)"
                            zip="73069"
                            className="cst loc_cs"
                          >
                            Oklahoma City (Norman) / Coming Soon
                          </option>
                          <option
                            value="Oklahoma City (Quail Springs)"
                            zip="73120"
                            className="cst loc_cs"
                          >
                            Oklahoma City (Quail Springs) / Coming Soon
                          </option>
                          <option
                            value="Stillwater"
                            zip="74075"
                            className="cst"
                          >
                            Stillwater
                          </option>
                          <option
                            value="Tulsa (Broken Arrow)"
                            zip="74012"
                            className="cst"
                          >
                            Tulsa (Broken Arrow)
                          </option>
                          <option
                            value="Tulsa (South)"
                            zip="74132"
                            className="cst"
                          >
                            Tulsa (South)
                          </option>
                        </optgroup>
                        <optgroup label="Pennsylvania">
                          <option value="Allentown" zip="18109" className="est">
                            Allentown
                          </option>
                          <option value="Erie" zip="16565" className="est">
                            Erie
                          </option>
                          <option
                            value="Harrisburg"
                            zip="17110"
                            className="est"
                          >
                            Harrisburg
                          </option>
                          <option
                            value="Harrisburg (Carlisle)"
                            zip="17013"
                            className="est"
                          >
                            Harrisburg (Carlisle)
                          </option>
                          <option value="Lancaster" zip="17601" className="est">
                            Lancaster
                          </option>
                          <option
                            value="Philadelphia (Jenkintown)"
                            zip="19046"
                            className="est"
                          >
                            Philadelphia (Jenkintown)
                          </option>
                          <option
                            value="Philadelphia (King of Prussia)"
                            zip="19406"
                            className="est"
                          >
                            Philadelphia (King of Prussia)
                          </option>
                          <option
                            value="Philadelphia (Springfield)"
                            zip="19064"
                            className="est"
                          >
                            Philadelphia (Springfield)
                          </option>
                          <option
                            value="Philadelphia (Bala Cynwyd)"
                            zip="19131"
                            className="est loc_cs"
                          >
                            Philadelphia (Bala Cynwyd) / Coming Soon
                          </option>
                          <option
                            value="Pittsburgh (Monroeville)"
                            zip="15146"
                            className="est"
                          >
                            Pittsburgh (Monroeville)
                          </option>
                          <option
                            value="Pittsburgh (Mt Lebanon)"
                            zip="15220"
                            className="est loc_cs"
                          >
                            Pittsburgh (Mt Lebanon) / Coming Soon
                          </option>
                          <option
                            value="Pittsburgh (Robinson)"
                            zip="15205"
                            className="est"
                          >
                            Pittsburgh (Robinson)
                          </option>
                          <option
                            value="Pittsburgh (Shadyside)"
                            zip="15232"
                            className="est loc_cs"
                          >
                            Pittsburgh (Shadyside) / Coming Soon
                          </option>
                          <option
                            value="Pittsburgh (Wexford)"
                            zip="15090"
                            className="est"
                          >
                            Pittsburgh (Wexford)
                          </option>
                          <option value="Reading" zip="19610" className="est">
                            Reading (Wyomissing)
                          </option>
                          <option value="Scranton" zip="18507" className="est">
                            Scranton
                          </option>
                          <option
                            value="State College"
                            zip="16801"
                            className="est loc_cs"
                          >
                            State College (Coming Soon)
                          </option>
                          <option
                            value="West Chester"
                            zip="19382"
                            className="est"
                          >
                            West Chester
                          </option>
                          <option value="York" zip="17402" className="est">
                            York
                          </option>
                        </optgroup>
                        <optgroup label="South Dakota">
                          <option
                            value="Sioux Falls"
                            zip="57108"
                            className="cst"
                          >
                            Sioux Falls
                          </option>
                        </optgroup>
                        <optgroup label="Tennessee">
                          <option
                            value="Chattanooga"
                            zip="37421"
                            className="est"
                          >
                            Chattanooga
                          </option>
                          <option
                            value="Clarksville"
                            zip="37040"
                            className="cst"
                          >
                            Clarksville
                          </option>
                          <option value="Knoxville" zip="37934" className="est">
                            Knoxville
                          </option>
                          <option value="Memphis" zip="38119" className="cst">
                            Memphis
                          </option>
                          <option
                            value="Nashville (Franklin)"
                            zip="37067"
                            className="cst"
                          >
                            Nashville (Franklin)
                          </option>
                          <option
                            value="Nashville (Hendersonville)"
                            zip="37075"
                            className="cst"
                          >
                            Nashville (Hendersonville)
                          </option>
                          <option
                            value="Murfreesboro"
                            zip="37129"
                            className="cst"
                          >
                            Murfreesboro
                          </option>
                          <option
                            value="Nashville (South)"
                            zip="37215"
                            className="cst"
                          >
                            Nashville (South)
                          </option>
                          <option
                            value="Tri-Cities"
                            zip="37601"
                            className="est"
                          >
                            Tri-Cities
                          </option>
                        </optgroup>
                        <optgroup label="Texas">
                          <option value="Austin" zip="78759" className="cst">
                            Austin (North)
                          </option>
                          <option
                            value="Austin (South)"
                            zip="78704"
                            className="cst"
                          >
                            Austin (South)
                          </option>
                          <option
                            value="College Station"
                            zip="77840"
                            className="cst"
                          >
                            College Station
                          </option>
                          <option
                            value="Corpus Christi"
                            zip="78412"
                            className="cst"
                          >
                            Corpus Christi
                          </option>
                          <option
                            value="Dallas (Addison)"
                            zip="75254"
                            className="cst"
                          >
                            Dallas (Addison)
                          </option>
                          <option
                            value="Dallas (Allen)"
                            zip="75002"
                            className="cst"
                          >
                            Dallas (Allen, TX)
                          </option>
                          <option
                            value="Dallas (Cedar Hill)"
                            zip="75104"
                            className="cst"
                          >
                            Dallas (Cedar Hill)
                          </option>
                          <option
                            value="Dallas (Denton)"
                            zip="76205"
                            className="cst"
                          >
                            Dallas (Denton)
                          </option>
                          <option
                            value="Dallas (Flower Mound)"
                            zip="75028"
                            className="cst"
                          >
                            Dallas (Flower Mound)
                          </option>
                          <option
                            value="Dallas (Irving)"
                            zip="75063"
                            className="cst"
                          >
                            Dallas (Irving)
                          </option>
                          <option
                            value="Dallas (Northeast)"
                            zip="75214"
                            className="cst"
                          >
                            Dallas Northeast
                          </option>
                          <option
                            value="Fort Worth (North Richland Hills)"
                            zip="76180"
                            className="cst"
                          >
                            Fort Worth (North Richland Hills)
                          </option>
                          <option
                            value="Fort Worth (South)"
                            zip="76132"
                            className="cst"
                          >
                            Fort Worth (South)
                          </option>
                          <option
                            value="Houston (Conroe)"
                            zip="77304"
                            className="cst"
                          >
                            Houston (Conroe)
                          </option>
                          <option
                            value="Houston (Cypress)"
                            zip="77429"
                            className="cst"
                          >
                            Houston (Cypress)
                          </option>
                          <option
                            value="Houston (Galleria)"
                            zip="77057"
                            className="cst"
                          >
                            Houston (Galleria)
                          </option>
                          <option
                            value="Houston (Humble)"
                            zip="77346"
                            className="cst"
                          >
                            Houston (Humble)
                          </option>
                          <option
                            value="Houston (Pearland)"
                            zip="77584"
                            className="cst"
                          >
                            Houston (Pearland)
                          </option>
                          <option
                            value="Houston (Richmond)"
                            zip="77407"
                            className="cst"
                          >
                            Houston (Richmond)
                          </option>
                          <option
                            value="Houston (Spring)"
                            zip="77380"
                            className="cst"
                          >
                            Houston (Spring)
                          </option>
                          <option
                            value="Houston (Webster)"
                            zip="77598"
                            className="cst"
                          >
                            Houston (Webster)
                          </option>
                          <option value="Killeen" zip="76548" className="cst">
                            Killeen
                          </option>
                          <option value="Lubbock" zip="79424" className="cst">
                            Lubbock
                          </option>
                          <option
                            value="New Braunfels"
                            zip="78130"
                            className="cst"
                          >
                            New Braunfels
                          </option>
                          <option value="Odessa" zip="79762" className="cst">
                            Odessa
                          </option>
                          <option
                            value="San Antonio"
                            zip="78216"
                            className="cst"
                          >
                            {" "}
                            San Antonio
                          </option>
                          <option
                            value="San Antonio (West)"
                            zip="78249"
                            className="cst"
                          >
                            San Antonio (West)
                          </option>
                          <option value="Tyler" zip="75703" className="cst">
                            Tyler
                          </option>
                          <option value="Waco" zip="76711" className="cst">
                            Waco
                          </option>
                        </optgroup>
                        <optgroup label="Utah">
                          <option
                            value="Salt Lake City (Bountiful)"
                            zip="84010"
                            className="mt"
                          >
                            Salt Lake City (Bountiful)
                          </option>
                          <option
                            value="Salt Lake City (Orem)"
                            zip="84097"
                            className="mt"
                          >
                            Salt Lake City (Orem)
                          </option>
                          <option
                            value="Salt Lake City (Riverdale)"
                            zip="84405"
                            className="mt"
                          >
                            Salt Lake City (Riverdale)
                          </option>
                          <option
                            value="Salt Lake City (Sandy)"
                            zip="84070"
                            className="mt"
                          >
                            Salt Lake City (Sandy)
                          </option>
                        </optgroup>
                        <optgroup label="Wisconsin">
                          <option value="Appleton" zip="54914" className="cst">
                            Appleton
                          </option>
                          <option value="Green Bay" zip="54304" className="cst">
                            Green Bay
                          </option>
                          <option
                            value="Janesville"
                            zip="53545"
                            className="cst"
                          >
                            Janesville
                          </option>
                          <option value="Madison" zip="53719" className="cst">
                            {" "}
                            Madison
                          </option>
                          <option
                            value="Madison (Sun Prairie)"
                            zip="53590"
                            className="cst"
                          >
                            Madison (Sun Prairie)
                          </option>
                          <option value="Milwaukee" zip="53045" className="cst">
                            Milwaukee (Brookfield)
                          </option>
                          <option
                            value="Milwaukee NS"
                            zip="53217"
                            className="cst"
                          >
                            Milwaukee (Whitefish Bay)
                          </option>
                          <option
                            value="Milwaukee (Wauwatosa)"
                            zip="53222"
                            className="cst"
                          >
                            Milwaukee (Wauwatosa)
                          </option>
                          <option
                            value="Menomonee Falls"
                            zip="53051"
                            className="cst"
                          >
                            Milwaukee (Menomonee Falls)
                          </option>
                          <option value="Wausau" zip="54401" className="cst">
                            Wausau
                          </option>
                        </optgroup>
                      </Select>
                    </FormControl>
                    <Button
                      onClick={handleBookConsult}
                      style={{
                        display: "block",
                        margin: "auto",
                        marginTop: 25,
                        backgroundColor: "#f93500",
                        color: "white",
                        borderRadius: 0
                      }}
                    >
                      Submit
                    </Button>
                    <Typography
                      variant="caption"
                      style={{
                        marginTop: 10,
                        marginLeft: "10%",
                        marginRight: "10%"
                      }}
                    >
                      I consent by electronic signature to be contacted by Milan
                      Laser by live agent, email & automatic telephone dialer
                      for information, offers or advertisements via email/ phone
                      call/ text message at the number &amp; email provided. No
                      purchase necessary.
                    </Typography>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          style={{
            justifyContent: "center",
            paddingLeft: "5%",
            paddingRight: "5%",
            marginTop: 35,
            marginBottom: 35
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            style={{ textAlign: "center", flexDirection: "column" }}
          >
            <img alt="brochure" src={brochure} width="80%" />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              textAlign: "center",
              paddingLeft: "2%",
              paddingRight: "2%",
              margin: "auto"
            }}
          >
            <Box component="span" display={{ xs: "none", sm: "block" }}>
              <Typography variant="h6">
                Say goodbye to razors or costly waxes, and say hello to the
                fastest way to permanently smooth skin. Laser hair removal means
                you can bare it all without the hassles of ingrown hairs killing
                the mood.
              </Typography>
            </Box>
            <Typography variant="h4" style={{ marginTop: 25 }}>
              Enter Your Code
            </Typography>
            <TextField
              style={{ marginTop: 20, width: 240 }}
              inputProps={{
                maxLength: 6,
                style: {
                  fontSize: 24,
                  letterSpacing: 20,
                  textTransform: "uppercase"
                }
              }}
              variant="outlined"
              label="Code"
              value={couponInput}
              onChange={e => {
                setCouponInput(e.target.value);
              }}
              error={couponError}
              helperText={errorText}
            />
            <Button
              size="large"
              onClick={handleClick}
              style={{
                display: "block",
                margin: "auto",
                marginTop: 25,
                backgroundColor: "#f93500",
                color: "white",
                borderRadius: 0
              }}
              variant="contained"
            >
              See Your Discount
            </Button>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
}

export default App;
